.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensures the container takes the full height */
    width: 100%; /* Ensures the container takes the full width */
  }
  
  .label {
    font-size: 15px;
    color: #ff0000;
    margin-bottom: 1px;
    text-align: center;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the bars horizontally */
  }
  
  .bar {
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
  }
  
  .bar:nth-child(2) {
    height: 35px;
    margin: 0 5px;
    animation-delay: .25s;
  }
  
  .bar:nth-child(3) {
    animation-delay: .5s;
  }
  
  @keyframes scale-up4 {
    20% {
      background-color: #ffffff;
      transform: scaleY(1.5);
    }
  
    40% {
      transform: scaleY(1);
    }
  }
  
/* chatwindow close loader */
.close_loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  background: linear-gradient(90deg,#B01865 50%,#0000 0) right/200% 100%;
  animation: l21 10s infinite linear;
  border-radius: 25px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.close_loader::before {
  content: "Closing...";
  color: #fff;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg,#fff 50%,#000 0);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l21 {
  100% {
    background-position: left
  }
}