@tailwind base;
@tailwind components;
@tailwind utilities;


/* Chat fixed position */
.chat-fixed-position {
    position: fixed;
    bottom: 16px;
    right: 16px;
}
  
  /* Chat background color */
.chat-bg-color {
    background-color: #006D77;
}
  
  /* Chat rounded button */
.chat-rounded-button {
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
}
  
  /* Chat icon size */
.chat-icon-size {
    width: 48px;
    height: 48px;
}
  
  /* Chat container */
.chat-container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 450px;
    height: 500px;
    background-color: #DCDCDC;
    border-radius: 12px;
    box-shadow: 0px 0px 10px #888888;
}
  
  /* Chat header */
.chat-header {
    display: flex;
    justify-content: space-between;
    background-color: #2F4F4F;
    color: white;
    padding: 12px;
    border-radius: 12px 12px 0 0;
}
  
  /* Chat title */
.chat-title {
    font-size: 24px;
    font-weight: bold;
    margin-left: 20px;
}
  
  /* Chat minus icon */
.chat-minus-icon {
    cursor: pointer;
    width: 32px;
    height: 16px;
    margin-right: 20px;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotateAnimation 1s linear infinite;
}
